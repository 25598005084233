
import { number_to_currency, as_json } from '@/lib/helpers'
import { DateTime } from 'luxon'
import ComboItems from '@/components/combo_items'
import { nextTick } from 'vue'

export default {
  components: { ComboItems },

  mounted: ->
    @$store.dispatch('checkout/validate')
    .then (validation) =>
      @handle_validation(validation)
    @fetch_timeslot_options()
    @scroll_to(@$refs['controls'], 1000)
    @$store.commit('layout/page_nav', {
      title: @$t('checkout_confirmation_header'),
      back_target: @navigate_back_target
    })

  beforeDestroy: ->
    clearTimeout(@scroll_to_bottom_timeout)
    clearTimeout(@scroll_timeout)

  data: -> {
    checkout_outro: false
    errors: []
    scroll_to_bottom_timeout: null
    scroll_timeout: null
    timeslot_options: []
    timeslots_loading: false
    validation_modal: false
    discount_codes_enabled: false
    discount_code_error: null
    discount_in_cents: 0
    discount_code: ''
    dispatch_error: null
  }

  watch: {
    payment_provider: ->
      @fetch_timeslot_options()
  }

  computed: {
    navigate_back_target: ->
      if @$store.getters['checkout/shipping_options'].length > 1
        '/checkout/' + if @requires_shipping then 'shipping_address' else 'dispatch'
      else '/basket'

    client_config: -> @$store.state.checkout.client_config

    stokvel: -> @$store.getters['application/feature_stokvel']

    payment_provider: {
      get: -> @$store.state.checkout.payment_provider
      set: (provider) -> @$store.commit('checkout/set_payment_provider', provider)
    }

    requires_bags: {
      get: ->
        @$store.state.checkout.requires_bags || false
      set: (value) ->
        @$store.commit('checkout/set_requires_bags', value)
    }

    collection_date: {
      get: ->
        @$store.state.checkout.collection_date
      set: (value) ->
        @dispatch_error = null
        @$store.commit('checkout/set_collection_date', value)
    }

    discount_code_input: {
      get: -> @$store.state.checkout.discount_code_input
      set: (value) -> @$store.commit('checkout/set_discount_code_input', value)
    }

    dispatch_method: -> if @requires_shipping then 'delivery' else 'collection'

    formatted_dispatch_method: -> @dispatch_method[0].toUpperCase() + @dispatch_method.slice(1)

    requires_shipping: -> @$store.state.checkout.requires_shipping

    subtotal: -> @$store.getters['checkout/subtotal_in_cents']

    credit_in_cents: -> @$store.state.checkout.credit_in_cents

    bag_price_in_cents: ->
      if @$store.getters['application/is_agency']
        @$store.state.application.features.bag_price_in_cents
      else
        @$store.state.checkout.client_config.bag_price_in_cents

    bags_feature_enabled: ->
      if @$store.getters['application/is_agency']
        @$store.state.application.features.bags
      else
        @$store.state.checkout.client_config.bags ? true : false

    total_savings_in_cents: ->

      savings_in_cents = 0
      for item in @items.filter((item) -> item.offer.base_price_in_cents?)
        savings_in_cents += @item_savings_in_cents(item) * item.qty
      savings_in_cents

    is_delivery_order: ->
      @dispatch_method.toUpperCase() == 'DELIVERY'

    delivery_cost_in_cents: ->
      @$store.state.checkout.shipping_cost_in_cents

    formatted_total: ->
      @number_to_currency(@$store.getters['checkout/total_in_cents'])

    payment_options: ->
      options = @$store.getters['checkout/payment_options']
      options = options.filter((option) => option != 'MOYA' || JSON.parse(window.localStorage.getItem('moya-discover')))
      @payment_provider = if @payment_provider in options then @payment_provider else options[0]
      ({ value: option, text: @payment_options_text(option) } for option in options)

    items: -> @$store.state.checkout.items

    loading: ->
      @$store.state.checkout.loading

    order_id: ->
      @$store.state.orders.current?.id

    shipping_address: ->
      if @$store.state.checkout.requires_shipping
        @$store.state.checkout.shipping_address.formatted
      else
        @$store.state.checkout.supplier.formatted_address

    remote_validation_failed: ->
      !@$store.state.checkout.validated_at ||
      @$store.state.checkout.updated ||
      @$store.state.checkout.out_of_stock ||
      @$store.state.checkout.total_error ||
      @$store.state.checkout.discount_code_error ||
      @dispatch_error
  }

  methods: {
    formatted_item_savings: (item) ->
      savings_in_cents = @item_savings_in_cents(item)
      return @number_to_currency(savings_in_cents * item.qty) if savings_in_cents > 0
      null

    item_savings_in_cents: (item) ->
      return null unless item.offer
      pack_size = Number(item.offer.sellable.pack)
      base_price = Number(item.offer.base_price_in_cents)
      price = Number(item.offer.price_in_cents)
      return null unless pack_size > 1
      return null unless base_price > 0
      cost_at_base_price = base_price * pack_size
      cost_at_base_price - price

    formatted_unit_price: (offer) ->
      text = "#{@$store.getters['application/currency']} #{offer.price}"
      if offer.per_kg then "#{text} /#{offer.per_kg_symbol}" else text

    formatted_qty: (item) ->
      if item.offer.per_kg
        qty = Number(item.qty) / Number(item.offer.per_kg_conversion_rate)
        qty = if item.offer.per_kg_symbol.toLowerCase() in ['kg', 'l'] then qty.toFixed(3) else qty.toFixed(0)
        # qty = Math.floor(Number(qty).toFixed(2) * 20)/20
        "#{qty} #{item.offer.per_kg_symbol}"
      else
        item.qty

    payment_options_text: (provider) ->
      map = {
        CASH: @requires_shipping && @$t('checkout_confirm_payment_option_cash_deliver') || @$t('checkout_confirm_payment_option_cash_collect'),
        PAYGATE: @$t('checkout_confirm_payment_option_paygate'),
        DEBTORCARD: @$t('checkout_confirm_payment_option_debtorcard'),
        SHOP2SHOP: 'Shop2Shop',
        MOYA: 'MoyaPay',
        YOCO: 'Pay Online (Card or EFT)'
      }
      map[provider]

    number_to_currency: (cents) ->
      number_to_currency({ symbol: @$store.getters['application/currency'], cents: cents })

    item_class: (item) ->
      if item.updated || item.offer.out_of_stock_at then '' else ''

    confirm_order: ->
      return if not @valid_selections()
      @$store.dispatch('checkout/validate').then (validation) =>
        @dispatch_error = validation.dispatch_error
        if @remote_validation_failed
          @handle_validation(validation)
          return
        @$store.dispatch('checkout/register_order').then (order) =>
          return if not !!@$store.state.orders.current

          if @$store.getters['checkout/requires_online_payment']
            @$router.push("/checkout/payment?order_id=#{order.id}")
          else
            # complete order
            @$store.commit('checkout/show_outro', true)
            @$store.dispatch('analytics/purchase')
            @$router.push("/orders/#{order.id}").then () =>
              @$store.dispatch('checkout/reset')
        .catch (error) -> console.error 'confirmation.confirm_order.register_order.caught:', error
      .catch (error) -> console.error 'confirmation.confirm_order.validate.caught:', error

    valid_selections: ->
      @payment_error = null
      if !@collection_date
        @dispatch_error = @$t('checkout_confirm_dispatch_slot_required', { dispatch: @formatted_dispatch_method })
        return false
      if !@payment_provider
        @payment_error = @$t('checkout_confirm_payment_method_required')
        return false
      true

    fetch_timeslot_options: ->
      @timeslots_loading = true
      @timeslot_options = []
      if typeof @collection_date == String
        @previous_collection_date = @collection_date.slice()
        @collection_date = null
      @$store.dispatch('checkout/fetch_timeslot_options')
        .then (timeslots) =>
          @timeslot_options = timeslots
          @collection_date = @timeslot_options[0].value if @timeslot_options.length == 1
          if @previous_collection_date in (option.value for option in timeslots)
            @collection_date = @previous_collection_date.slice()
        .catch (error) =>
          @dispatch_error = 'Problem fetching timeslots. Please try again'
          console.error 'fetch supplier timeslots error:', error
        .finally =>
          @timeslots_loading = false

    validate_discount_code: ->
      @$store.dispatch('checkout/validate')
      .then (validation) => @handle_validation(validation)

    handle_validation: (validation) ->
      @validation_modal = true if validation.out_of_stock
      @discount_codes_enabled = validation.discount_codes_enabled
      @$store.dispatch('cart/reset', validation.items)
      if validation.updated
        @errors.push(@$t('checkout_validation_prices_updated_line_1'))
        @errors.push(@$t('checkout_validation_prices_updated_line_2'))
      if validation.total_error
        @errors.push(@$t('checkout_validation_order_minimum_total_line_1'))
      if validation.discount_code_error == 'invalid'
        @discount_code_error = @$t('checkout_validation_discount_code_invalid')
        @discount_in_cents = 0
      else
        @discount_code_error = null
        @discount_in_cents = validation.discount_in_cents
        @discount_code = validation.discount_code

      @$nextTick =>
        @scroll_to(@$refs['error-alert']) if @errors.length > 0

    scroll_to: (element) ->
      clearTimeout(@scroll_to_bottom_timeout) if @scroll_to_bottom_timeout
      @scroll_timeout = setTimeout =>
        @$vuetify.goTo(element, { offset: 8, container: document.documentElement })
      , 200
  }
}
